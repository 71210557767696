<template>
    <div class="bg-light mt-3 px-md-4 px-3 py-2">
        <div>
            <div id="local_form" class="w-100">
                <div>
                    <h5>Ajustes de página</h5>
                </div>
                <div class="row">
                    <div class="col">
                        <label>Ajuste asociado</label>
                        <el-select
                            v-model="setting_id"
                            class="w-100"
                            @change="getSettingsPages($event)"
                        >
                            <el-option
                                v-for="setting in settings"
                                :key="setting.id"
                                :label="
                                    ' Entidad:' +
                                    setting.country.name +
                                    ' | Lenguaje:' +
                                    setting.language.name
                                "
                                :value="setting.id"
                            >
                            </el-option>
                        </el-select>
                        <hr />
                    </div>
                </div>
                <el-form
                    class="row w-100 p-0 mx-0"
                    v-if="setting_id"
                    ref="ruleForm"
                    id="ruleForm"
                    :rules="rules"
                    :model="settingsPage"
                    v-loading="waiting"
                    @submit.prevent="submitForm('ruleForm')"
                    status-icon
                >
                    <div class="row mx-0 w-100">
                        <label
                            class="pt-2 mr-3"
                            style="font-size: 1rem"
                            id="description_1"
                            >Linea ética
                        </label>
                    </div>

                    <div class="row mx-0 w-100 d-flex justify-content-between">
                        <el-form-item
                            prop="description_1"
                            class="col-md-12 px-0"
                        >
                            <label class="pt-2 mr-3">Descripción: </label>
                            <el-input
                                type="textarea"
                                :rows="5"
                                v-model="settingsPage.description_1"
                                placeholder="Ingrese la descripción"
                            ></el-input>
                        </el-form-item>
                    </div>

                    <div class="row mx-0 w-100">
                        <label
                            class="pt-2 mr-3"
                            style="font-size: 1rem"
                            id="description_2"
                            >Buenas practicas
                        </label>
                    </div>

                    <div class="row mx-0 w-100">
                        <el-form-item prop="description_2" class="col px-0">
                            <label class="pt-2 mr-3">Descripción: </label>
                            <el-input
                                type="textarea"
                                :rows="5"
                                v-model="settingsPage.description_2"
                                placeholder="Ingrese la descripción"
                            ></el-input>
                        </el-form-item>
                    </div>

                    <div class="row mx-0 w-100">
                        <label
                            class="pt-2"
                            style="font-size: 1rem"
                            id="description_3"
                            >Página 3
                        </label>

                        <div class="row mx-0 w-100">
                            <el-form-item
                                prop="title_3"
                                id="title_3"
                                class="col-md-3 px-1"
                                style="align-items: flex-start !important"
                            >
                                <label class="pt-2">Título: </label>
                                <el-input
                                    type="text"
                                    placeholder="Ingrese el título"
                                    v-model="settingsPage.title_3"
                                />
                            </el-form-item>
                            <el-form-item
                                prop="description_3"
                                class="col-md-9 px-1"
                            >
                                <label class="pt-2">Descripción: </label>
                                <el-input
                                    type="textarea"
                                    :rows="6"
                                    v-model="settingsPage.description_3"
                                    placeholder="Ingrese la descripción"
                                ></el-input>
                            </el-form-item>
                        </div>
                    </div>

                    <div class="row mx-0 w-100">
                        <label
                            class="pt-2"
                            style="font-size: 1rem"
                            id="description_4"
                            >Página 4</label
                        >

                        <div class="row mx-0 w-100">
                            <el-form-item
                                prop="title_4"
                                id="title_4"
                                class="col-md-3 px-1"
                                style="align-items: flex-start !important"
                            >
                                <label class="pt-2">Título: </label>
                                <el-input
                                    type="text"
                                    placeholder="Ingrese el título"
                                    v-model="settingsPage.title_4"
                                />
                            </el-form-item>
                            <el-form-item
                                prop="description_4"
                                class="col-md-9 px-1"
                            >
                                <label class="pt-2">Descripción: </label>
                                <el-input
                                    type="textarea"
                                    :rows="6"
                                    v-model="settingsPage.description_4"
                                    placeholder="Ingrese la descripción"
                                ></el-input>
                            </el-form-item>
                        </div>
                    </div>
                    <div class="row mx-0 w-100">
                        <label
                            class="pt-2"
                            style="font-size: 1rem"
                            id="description_5"
                            >Página 5</label
                        >
                    </div>
                    <div class="row mx-0 w-100">
                        <el-form-item
                            prop="title_5"
                            id="title_5"
                            class="col-md-3 px-1"
                            style="align-items: flex-start !important"
                        >
                            <label class="pt-2">Título: </label>
                            <el-input
                                type="text"
                                placeholder="Ingrese el título"
                                v-model="settingsPage.title_5"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="description_5"
                            class="col-md-9 px-1"
                        >
                            <label class="pt-2">Descripción: </label>
                            <el-input
                                type="textarea"
                                :rows="6"
                                v-model="settingsPage.description_5"
                                placeholder="Ingrese la descripción"
                            ></el-input>
                        </el-form-item>
                    </div>
                </el-form>
            </div>
            <div
                class="d-flex justify-content-center p-2 mb-3 w-100"
                v-if="setting_id"
            >
                <button
                    @click.prevent="submitForm('ruleForm')"
                    :disabled="waiting"
                    class="btn btn-info mr-4 text-white mx-auto"
                >
                    Guardar
                </button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ["settings", "settingsPages"],
    data() {
        let checkDescription = (rule, value, callback) => {
            if (!this.truthty(value)) {
                let name = "";
                if (rule.field == "description_1") name = "la linea ética";
                else if (rule.field == "description_2")
                    name = "las buenas practicas";
                else if (rule.field == "description_3") name = "la pagina 3";
                else if (rule.field == "description_4") name = "la pagina 3";
                else if (rule.field == "description_5") name = "la pagina 3";
                return callback(
                    new Error(`Debe agregar la descripción de ${name}`)
                );
            }
            return callback();
        };
        let checkTitle = (rule, value, callback) => {
            if (!this.truthty(value)) {
                let name = "";
                if (rule.field == "title_3") name = "3";
                else if (rule.field == "title_4") name = "4";
                else if (rule.field == "title_5") name = "5";
                return callback(
                    new Error(`Debe agregar el título de la pagina ${name}`)
                );
            }
            return callback();
        };
        let checkFile = (rule, value, callback) => {
            if (!this.truthty(value)) {
                let name = "";
                if (rule.field == "privacy") name = "política de privacidad";
                else if (rule.field == "prevention")
                    name = "modelo de prevención";
                else if (rule.field == "interaction")
                    name = "protocolo de interacción";
                return callback(
                    new Error(`Debe agregar el archivo de ${name}`)
                );
            }
            return callback();
        };
        return {
            settingsPage: {
                description_1: "",
                description_2: "",
                description_3: "",
                description_4: "",
                description_5: "",
                title_3: "",
                title_4: "",
                title_5: "",
            },
            setting_id: undefined,
            waiting: false,
            rules: {
                title_3: [
                    { validator: checkTitle, trigger: ["change", "blur"] },
                ],
                title_4: [
                    { validator: checkTitle, trigger: ["change", "blur"] },
                ],
                title_5: [
                    { validator: checkTitle, trigger: ["change", "blur"] },
                ],
            },
        };
    },
    methods: {
        submitForm(formName) {
            this.$refs[formName].validate((valid, rules) => {
                if (valid) {
                    this.submit();
                } else {
                    this.goToError(rules);
                }
            });
        },
        clearForm() {
            this.settingsPage = {
                description_1: "",
                description_2: "",
                description_3: "",
                description_4: "",
                description_5: "",
                title_3: "",
                title_4: "",
                title_5: "",
            };
            this.$refs["ruleForm"].resetFields();
        },
        getSettingsPages($event) {
            const url = `${window.location.origin}/settingsPage/getAll/${this.setting_id}`;
            axios
                .get(url, {})
                .then((res) => {
                    this.settingsPage = {
                        description_1: this.settingsPages[0].description,
                        description_2: this.settingsPages[1].description,
                        description_3: this.settingsPages[2].description,
                        description_4: this.settingsPages[3].description,
                        description_5: this.settingsPages[4].description,
                        title_3: this.settingsPages[2].title,
                        title_4: this.settingsPages[3].title,
                        title_5: this.settingsPages[4].title,
                    };
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        handlePreviewPrivacy(file) {
            if (this.truthty(file.url)) {
                window.open(file.url, "_blank");
            }
        },
        handlePreviewPrevention(file) {
            if (this.truthty(file.url)) {
                window.open(file.url, "_blank");
            }
        },
        handlePreviewInteraction(file) {
            console.log(file);
            if (this.truthty(file.url)) {
                window.open(file.url, "_blank");
            }
        },
        handleChangePrivacy($e) {
            this.settingsPage.privacy.push($e.raw);
            this.$refs["ruleForm"].validateField("privacy");
        },
        handleChangePrevention($e) {
            this.settingsPage.prevention.push($e.raw);
            this.$refs["ruleForm"].validateField("prevention");
        },
        handleChangeInteraction($e) {
            this.settingsPage.interaction.push($e.raw);
            this.$refs["ruleForm"].validateField("interaction");
        },
        handleRemovePrivacy(file, privacy) {
            this.settingsPage.privacy = privacy;
            this.$refs["ruleForm"].validateField("privacy");
        },
        handleRemovePrevention(file, prevention) {
            this.settingsPage.prevention = prevention;
            this.$refs["ruleForm"].validateField("prevention");
        },
        handleRemoveInteraction(file, interaction) {
            this.settingsPage.interaction = interaction;
            this.$refs["ruleForm"].validateField("interaction");
        },
        handleExceedPrivacy(files, privacy) {
            this.$message.warning(
                `Solo puedes subir 1 archivo de política de privacidad.`
            );
        },
        handleExceedPrevention(files, prevention) {
            this.$message.warning(
                `Solo puedes subir 1 archivo de modelo de prevención.`
            );
        },
        handleExceedInteraction(files, interaction) {
            this.$message.warning(
                `Solo puedes subir 1 archivo de protocolo de interacción.`
            );
        },
        submit() {
            this.waiting = true;
            const url = `${window.location.origin}/settingsPage/update/${this.setting_id}`;
            let formData = new FormData();
            formData.append("payload", JSON.stringify(this.settingsPage));
            axios
                .post(url, formData)
                .then((res) => {
                    this.waiting = false;
                    this.clearForm();
                    this.setting_id = undefined;
                    this.$message({
                        message: res.data.message,
                        type: "success",
                    });
                    let element = document.getElementById("ruleForm");
                    if (this.truthty(element))
                        element.scrollIntoView({ behavior: "smooth" });
                })
                .catch((error) => {
                    this.waiting = false;
                    console.log("error");
                    this.$message({
                        message: error.response.data.message,
                        type: "error",
                        duration: 2000,
                    });
                });
        },
    },
};
</script>
