<template>
    <div class="bg-light mt-3 px-md-4 px-3 py-2">
        <div>
            <div id="form_output"></div>

            <el-collapse
                v-model="activeNames"
                class="w-100 border-bottom-0 mb-3"
            >
                <el-collapse-item name="1" class="div-filters">
                    <template slot="title"
                        ><h5 class="pl-3">
                            <i class="el-icon-plus mr-2" />Nuevo local / oficina
                        </h5>
                    </template>

                    <el-form
                        id="local_form"
                        ref="ruleForm"
                        :rules="rules"
                        :model="local"
                        @submit.prevent="submitForm('ruleForm')"
                        status-icon
                    >
                        <div
                            class="row w-100 mx-0 px-1 mb-4 d-flex justify-content-between align-items-end"
                        >
                            <el-form-item
                                class="col-md-4 m-0"
                                prop="internal_id"
                                id="internal_id"
                            >
                                <label>Código interno</label>
                                <el-input
                                    type="number"
                                    v-model="local.internal_id"
                                    placeholder="Ingrese código interno"
                                    :disabled="isEdition"
                                />
                            </el-form-item>
                            <el-form-item
                                class="col-md-4 m-0"
                                prop="name"
                                id="name"
                            >
                                <label>Nombre de local u oficina</label>
                                <el-input
                                    v-model="local.name"
                                    placeholder="Ingrese el nombre del local u oficina"
                                />
                            </el-form-item>
                            <el-form-item
                                class="col-md-4 m-0"
                                prop="internal_name"
                                id="internal_name"
                            >
                                <label>Nombre interno</label>
                                <el-input
                                    v-model="local.internal_name"
                                    placeholder="Ingrese nombre interno"
                                />
                            </el-form-item>
                        </div>

                        <div
                            class="row w-100 mx-0 px-1 mb-4 d-flex justify-content-between align-items-end"
                        >
                            <el-form-item
                                class="col-md-5 m-0"
                                prop="country_id"
                                id="country_id"
                            >
                                <label>Entidad</label>
                                <el-select
                                    class="w-100"
                                    v-model="local.country_id"
                                    :disabled="false"
                                    filterable
                                >
                                    <el-option
                                        v-for="country in countries"
                                        :key="country.id"
                                        :label="country.name"
                                        :value="country.id"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item
                                class="col-md-5 m-0"
                                prop="place_id"
                                id="place_id"
                            >
                                <label>Lugar o zona</label>
                                <el-select
                                    class="w-100"
                                    v-model="local.place_id"
                                    :disabled="false"
                                    filterable
                                >
                                    <el-option
                                        class="w-100"
                                        v-for="place in places"
                                        :key="place.id"
                                        :label="place.name"
                                        :value="place.id"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <div class="col-md-2 pt-3 text-right">
                                <button
                                    @click.prevent="submitForm('ruleForm')"
                                    class="btn btn-info text-white"
                                >
                                    {{ isEdition ? "Actualizar" : "Guardar" }}
                                </button>
                            </div>
                        </div>
                    </el-form>
                </el-collapse-item>
            </el-collapse>
        </div>
        <!-- ------------------ -->
        <div class="row w-100 mx-0">
            <el-table
                :data="locals"
                style="width: 100%"
                max-height="500"
                v-loading="waiting"
            >
                <el-table-column prop="internal_id" label="Cód" width="80">
                </el-table-column>
                <el-table-column
                    prop="name"
                    label="Oficina u oficina"
                    width="140"
                >
                </el-table-column>
                <el-table-column
                    prop="internal_name"
                    label="Nombre interno"
                    width="140"
                >
                </el-table-column>
                <el-table-column
                    prop="country.name"
                    label="Entidad"
                    width="180"
                    :filters="countryFilters()"
                    :filter-method="filterCountries"
                >
                </el-table-column>
                <el-table-column
                    prop="place.name"
                    label="Lugar o zona"
                    width="180"
                    :filters="placeFilters()"
                    :filter-method="filterPlaces"
                >
                </el-table-column>
                <el-table-column
                    label="Estado"
                    min-width="100"
                    :filters="[
                        { text: 'Activo', value: 1 },
                        { text: 'Inactivo', value: 0 },
                    ]"
                    :filter-method="filterState"
                >
                    <template slot-scope="scope">
                        <el-switch
                            class="align-items-center switch-compact switch"
                            @change="editLocalState(scope.$index)"
                            :value="scope.row.active == 1"
                            active-text="Activo"
                            inactive-text="Inactivo"
                        >
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column
                    class-name="item-center"
                    fixed="right"
                    label="Acciones"
                    min-width="100"
                >
                    <template slot-scope="scope">
                        <el-button
                            size="small"
                            @click="editLocal(scope.$index)"
                            :type="
                                isEdition && scope.$index == editedLocalIndex
                                    ? 'warning'
                                    : 'primary'
                            "
                            :icon="
                                isEdition && scope.$index == editedLocalIndex
                                    ? 'el-icon-close'
                                    : 'el-icon-edit'
                            "
                        />
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
export default {
    props: ["countries", "places"],
    data() {
        return {
            activeNames: [],
            local: {
                name: "",
                internal_id: undefined,
                internal_name: "",
                country_id: undefined,
                place_id: undefined,
            },
            locals: [],
            editedLocalId: undefined,
            isEdition: false,
            editedLocalIndex: undefined,
            rules: {
                internal_id: [
                    {
                        required: true,
                        message: "Debe escribir el tipo de la código interno",
                    },
                ],
                name: [
                    {
                        required: true,
                        message: "Debe escribir el nombre de la entidad",
                        trigger: ["change", "blur"],
                    },
                ],
                country_id: [
                    {
                        required: true,
                        message: "Debe selecionar el pais",
                        trigger: ["change", "blur"],
                    },
                ],
                internal_name: [
                    {
                        required: true,
                        message: "Debe escribir el nombre interno",
                        trigger: ["change", "blur"],
                    },
                ],
                place_id: [
                    {
                        required: true,
                        message: "Debe selecionar el lugar",
                        trigger: ["change", "blur"],
                    },
                ],
            },
            waiting: false,
        };
    },
    methods: {
        submitForm(formName) {
            this.$refs[formName].validate((valid, rules) => {
                if (valid) {
                    this.submit();
                } else {
                    this.goToError(rules);
                }
            });
        },
        countryFilters() {
            const filters = this.countries.map((country) => {
                return {
                    text: country.name,
                    value: country.id,
                };
            });
            return filters;
        },
        filterCountries(value, row) {
            return row.country_id == value;
        },
        placeFilters() {
            const filters = this.places.map((place) => {
                return {
                    text: place.name,
                    value: place.id,
                };
            });
            return filters;
        },
        filterPlaces(value, row) {
            return row.place_id == value;
        },
        filterState(value, row) {
            return row.active == value;
        },
        getLocals() {
            const url = `${window.location.origin}/locals/getAll`;
            axios
                .post(url, {})
                .then((res) => {
                    this.locals = res.data.locals;
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        clearForm() {
            this.local = {
                name: "",
                internal_id: undefined,
                internal_name: "",
                country_id: undefined,
                place_id: undefined,
            };
            this.$refs["ruleForm"].resetFields();
        },
        clearEditData() {
            this.isEdition = false;
            this.editedLocalIndex = undefined;
            this.editedLocalId = undefined;
        },
        editLocal(localIndex) {
            if (this.editedLocalIndex == localIndex) {
                this.clearForm();
                this.clearEditData();
                return;
            }
            const local = this.locals[localIndex];
            const {
                id,
                name,
                internal_id,
                internal_name,
                country_id,
                place_id,
            } = local;
            this.isEdition = true;
            this.editedLocalId = id;
            this.editedLocalIndex = localIndex;
            this.local = {
                name: name,
                internal_id: internal_id,
                internal_name: internal_name,
                country_id: country_id,
                place_id: place_id,
            };
        },
        editLocalState(localIndex) {
            const local = this.locals[localIndex];
            const { id, name, internal_name, active } = local;
            this.editedLocalId = id;
            this.editedLocalIndex = localIndex;
            const data = {
                name: name,
                internal_name: internal_name,
                active: active == 1 ? 0 : 1,
            };
            this.submitEdit(data);
        },
        submit() {
            if (this.isEdition) {
                const { name, internal_name } = this.local;
                const data = {
                    name: name,
                    internal_name: internal_name,
                    active: 1,
                };
                this.submitEdit(data);
            } else {
                this.submitCreate();
            }
        },
        submitCreate() {
            this.waiting = true;
            const url = `${window.location.origin}/locals/create`;
            axios
                .post(url, this.local)
                .then((res) => {
                    this.waiting = false;
                    this.locals.push(res.data.newLocal);
                    this.clearForm();
                    this.$message({
                        message: res.data.message,
                        type: "success",
                    });
                })
                .catch((error) => {
                    this.waiting = false;
                    console.error(error);
                });
        },
        submitEdit(localData) {
            this.waiting = true;
            const url = `${window.location.origin}/locals/update/${this.editedLocalId}`;
            axios
                .put(url, localData)
                .then((res) => {
                    this.waiting = false;
                    const aux = this.locals.map((row) => {
                        if (row.id == this.editedLocalId) {
                            return res.data.local;
                        } else return row;
                    });
                    this.locals = this.copy(aux);

                    this.clearForm();
                    this.clearEditData();
                    this.$message({
                        message: res.data.message,
                        type: "success",
                    });
                })
                .catch((error) => {
                    this.waiting = false;
                    console.error(error);
                });
        },
    },
    mounted() {
        this.getLocals();
        if (this.checkMobile() == false) {
            this.activeNames = ["1"];
        }
    },
};
</script>
