var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-light mt-3 px-md-4 px-3 py-2"},[_c('div',[_vm._m(0),_vm._v(" "),(_vm.errors.length)?_c('div',[_c('el-alert',{attrs:{"type":"error"}},[_c('ul',{staticClass:"mb-0"},_vm._l((_vm.errors),function(error,index){return _c('li',{key:index},[_vm._v("\n                        "+_vm._s(error.message)+"\n                    ")])}),0)])],1):_vm._e(),_vm._v(" "),_c('div',{attrs:{"id":"form_output"}}),_vm._v(" "),_c('el-form',{ref:"ruleForm",staticClass:"col w-100 p-0",attrs:{"id":"local_form","rules":_vm.rules,"model":_vm.local,"status-icon":""},on:{"submit":function($event){$event.preventDefault();return _vm.submitForm('ruleForm')}}},[_c('div',{staticClass:"row w-100 d-flex justify-content-between mx-0"},[_c('el-form-item',{staticClass:"col pl-0",attrs:{"prop":"name","id":"name"}},[_c('el-input',{staticClass:"w-100",attrs:{"placeholder":"Ingrese el tipo de persona"},model:{value:(_vm.local.name),callback:function ($$v) {_vm.$set(_vm.local, "name", $$v)},expression:"local.name"}})],1),_vm._v(" "),_c('div',[_c('button',{staticClass:"btn btn-info text-white",attrs:{"size":"small"},on:{"click":function($event){$event.preventDefault();return _vm.submitForm('ruleForm')}}},[_vm._v("\n                        "+_vm._s(_vm.isEdition ? "Actualizar" : "Guardar")+"\n                    ")])])],1)])],1),_vm._v(" "),_c('div',{staticClass:"row w-100 mx-0"},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.waiting),expression:"waiting"}],staticStyle:{"width":"100%","height":"100%"},attrs:{"data":_vm.peopleList,"max-height":"500","default-sort":{ prop: 'id', order: 'ascending' }}},[_c('el-table-column',{attrs:{"prop":"id","label":"Cód","width":"90","sortable":""}}),_vm._v(" "),_c('el-table-column',{attrs:{"prop":"name","min-width":"250","label":"Nombre","sortable":""}}),_vm._v(" "),_c('el-table-column',{attrs:{"label":"Estado","min-width":"200","filters":[
                    { text: 'Activo', value: 1 },
                    { text: 'Inactivo', value: 0 },
                ],"filter-method":_vm.filterState},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('el-switch',{staticClass:"align-items-center switch-compact switch",attrs:{"value":props.row.active == 1,"active-text":"Activo","inactive-text":"Inactivo"},on:{"change":function($event){return _vm.editPersonState(props.row, props.$index)}}})]}}])}),_vm._v(" "),_c('el-table-column',{attrs:{"class-name":"item-center","label":"Acciones","min-width":"100","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('el-button',{attrs:{"size":"small","type":_vm.isEdition && props.$index == _vm.index
                                ? 'warning'
                                : 'primary',"icon":_vm.isEdition && props.$index == _vm.index
                                ? 'el-icon-close'
                                : 'el-icon-edit'},on:{"click":function($event){return _vm.edit(props.row, props.$index)}}})]}}])})],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h5',[_vm._v("Nuevo tipo de personas")])])
}]

export { render, staticRenderFns }