<template>
    <el-form
        :model="ruleForm"
        status-icon
        :rules="rules"
        ref="ruleForm"
        class="demo-ruleForm"
    >
        <!-- Información extra -->
        <el-form-item
            label="¿Tienes algún detalle extra que nos quieras contar?"
            class="w-100"
            id="extra_information"
            prop="extra_information"
        >
            <el-input
                type="textarea"
                :rows="3"
                placeholder="Describa la situación"
                v-model="ruleForm.extra_information"
                maxlength="500"
                show-word-limit
            ></el-input>
        </el-form-item>
        <!-- Cargar archivos -->
        <el-upload
            class="upload-demo"
            action="#"
            :on-change="handleChange"
            :on-remove="handleRemove"
            multiple
            :before-upload="onBeforeUpload"
            :limit="3"
            :on-exceed="handleExceed"
            :auto-upload="false"
            accept="image/jpeg,image/jpg,image/png, application/pdf, audio/mp3,audio/wap, video/mpg, video/mp4, video/mob"
        >
            <el-button size="small" type="primary"
                >Clic para subir archivo</el-button
            >
            <div slot="tip" class="el-upload__tip">
                Tamaño máximo de archivos (imagen, pdf, audio o video) 10MB
            </div>
        </el-upload>

        <hr />
        <div class="row">
            <div class="col d-flex justify-content-center">
                <vue-recaptcha
                    ref="recaptcha"
                    :sitekey="recaptcha"
                    :loadRecaptchaScript="true"
                    @verify="verifyrecaptcha"
                    @expired="onCaptchaExpired"
                    type="invisible"
                />
            </div>
        </div>
        <hr />

        <el-row type="flex" justify="space-between">
            <el-button type="info" plain @click="next(lastStep)"
                >Volver</el-button
            >
            <el-button
                :disabled="!truthty(recaptchaValue)"
                v-if="!waiting"
                type="primary"
                @click.prevent="submitForm('ruleForm')"
                >Siguiente</el-button
            >
            <el-button v-if="waiting" type="primary" :loading="true"
                >Guardando...</el-button
            >
        </el-row>
    </el-form>
</template>

<script>
export default {
    props: [
        "next",
        "lastStep",
        "setStepData",
        "complaint_id",
        "complaint",
        "recaptcha",
    ],
    data() {
        return {
            recaptchaValue: null,
            waiting: false,
            ruleForm: {
                complaint_id: this.$props.complaint_id,
                extra_information: "",
                fileList: [],
                recaptchaValue: "",
            },
            rules: {
                extra_information: [
                    {
                        min: 10,
                        message:
                            "Por favor informar más detalles de la situación",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    methods: {
        onCaptchaExpired() {
            this.$refs.recaptcha.reset();
        },
        verifyrecaptcha(response) {
            this.recaptchaValue = response;
            this.ruleForm.recaptchaValue = response;
        },
        onBeforeUpload(file) {
            const validTypes = [
                "image/jpeg",
                "image/pjg",
                "image/png",
                "application/pdf",
                "audio/mp3",
                "audio/wap",
                "video/mpg",
                "video/mp4",
                "video/mob",
            ];
            let isValid = validTypes.includes(file.type);

            const isLt10M = file.size / 10240 / 10240 < 10;

            if (!isValid) {
                this.$message.error(
                    "El archivo subido no cumple con los formatos aceptados"
                );
            }
            if (!isLt10M) {
                this.$message.error(
                    "El archivo subido supera el tamaño maximo"
                );
            }
            return isValid && isLt10M;
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid, rules) => {
                if (valid) {
                    this.waiting = true;
                    const url = `${window.location.origin}/complaint/postdata3`;
                    let config = {
                        headers: { "Content-Type": "multipart/form-data" },
                    };
                    let formData = new FormData();
                    formData.append("payload", JSON.stringify(this.ruleForm));
                    this.ruleForm.fileList.map((x, index) => {
                        formData.append("file" + (index + 1), x);
                    });
                    formData.append(
                        "complaint",
                        JSON.stringify(this.complaint)
                    );
                    axios
                        .post(url, formData, config)
                        .then((res) => {
                            this.waiting = false;
                            if (res.data.success) {
                                this.$props.setStepData(res.data);
                                this.$props.next(4);
                            }
                        })
                        .catch((e) => {
                            this.waiting = false;
                        });
                } else {
                    this.goToError(rules);
                }
            });
        },
        handleChange($e, fileList) {
            let isValid = this.onBeforeUpload($e.raw);
            if (isValid) {
                this.ruleForm.fileList.push($e.raw);
            } else {
                fileList.pop();
            }
        },
        handleRemove(file, fileList) {
            this.ruleForm.fileList = fileList;
        },
        handleExceed(files, fileList) {
            this.$message.warning(
                `El límite es de 3 archivos. Puedes eliminar algún archivo ya cargado para subir uno nuevo.`
            );
        },
    },
};
</script>
