<template>
    <div class="bg-light mt-3 px-md-4 px-3 py-2">
        <div>
            <div>
                <h5>Nuevo lugar o zona</h5>
            </div>
            <div id="form_output"></div>
            <el-form
                class="col w-100 p-0"
                id="local_form"
                ref="ruleForm"
                :rules="rules"
                :model="place"
                @submit.prevent="submitForm('ruleForm')"
                status-icon
            >
                <div class="row w-100 d-flex justify-content-between mx-0">
                    <el-form-item class="col pl-0" prop="name" id="name">
                        <el-input
                            class="w-100"
                            v-model="place.name"
                            placeholder="Ingrese el nombre del lugar o zona"
                        />
                    </el-form-item>
                    <div class="text-right">
                        <button
                            size="small"
                            @click.prevent="submitForm('ruleForm')"
                            class="btn btn-info text-white"
                        >
                            {{ isEdition ? "Actualizar" : "Guardar" }}
                        </button>
                    </div>
                </div>
            </el-form>
        </div>
        <!-- --------------------------- -->
        <div class="row w-100 mx-0">
            <el-table
                :data="places"
                style="width: 100%; height: 100%"
                max-height="350"
                v-loading="waiting"
                :default-sort="{ prop: 'id', order: 'ascending' }"
            >
                <el-table-column prop="id" label="Cód" width="90" sortable>
                </el-table-column>
                <el-table-column
                    sortable
                    prop="name"
                    label="Lugar o zona"
                    min-width="250"
                >
                </el-table-column>
                <el-table-column
                    label="Estado"
                    min-width="200"
                    :filters="[
                        { text: 'Activo', value: 1 },
                        { text: 'Inactivo', value: 0 },
                    ]"
                    :filter-method="filterState"
                >
                    <template slot-scope="scope">
                        <el-switch
                            class="align-items-center switch-compact switch"
                            @change="editPlaceState(scope.$index)"
                            :value="scope.row.active == 1"
                            active-text="Activo"
                            inactive-text="Inactivo"
                        >
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column
                    class-name="item-center"
                    fixed="right"
                    label="Acciones"
                    min-width="100"
                >
                    <template slot-scope="scope">
                        <el-button
                            size="small"
                            @click="editPlace(scope.$index)"
                            :type="
                                isEdition && scope.$index == index
                                    ? 'warning'
                                    : 'primary'
                            "
                            :icon="
                                isEdition && scope.$index == index
                                    ? 'el-icon-close'
                                    : 'el-icon-edit'
                            "
                        />
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
export default {
    props: ["places"],
    data() {
        return {
            place: {
                name: "",
                active: true,
            },
            index: -1,
            editedPlaceId: undefined,
            isEdition: false,
            rules: {
                name: [
                    {
                        required: true,
                        message: "Debe escribir el nombre del lugar o zona",
                        trigger: ["change", "blur"],
                    },
                ],
            },
            waiting: false,
        };
    },
    methods: {
        submitForm(formName) {
            this.$refs[formName].validate((valid, rules) => {
                if (valid) {
                    this.submit();
                } else {
                    this.goToError(rules);
                }
            });
        },
        filterState(value, row) {
            return row.active == value;
        },
        clearForm() {
            this.place = {
                name: undefined,
            };
            this.$refs["ruleForm"].resetFields();
        },
        editPlace(placeIndex) {
            if (this.index == placeIndex) {
                this.clearEditData();
                this.clearForm();
                return;
            }
            const place = this.places[placeIndex];
            const { id, name, active } = place;
            this.isEdition = true;
            this.editedPlaceId = id;
            this.index = placeIndex;
            this.place = {
                name: name,
                active: active,
            };
        },
        clearEditData() {
            this.index = -1;
            this.isEdition = false;
            this.editedLocalId = undefined;
        },
        editPlaceState(placeIndex) {
            const place = this.places[placeIndex];
            const { id, name, active } = place;
            this.editedPlaceId = id;
            this.index = placeIndex;
            const data = {
                name: name,
                active: active == 1 ? 0 : 1,
            };
            this.submitEdit(data);
        },
        submit() {
            if (this.isEdition) {
                const { name, active } = this.place;
                const data = {
                    name: name,
                    active: active,
                };
                this.submitEdit(data);
            } else {
                this.submitCreate();
            }
        },
        submitCreate() {
            this.waiting = true;
            const url = `${window.location.origin}/places/create`;
            axios
                .post(url, this.place)
                .then((res) => {
                    this.waiting = false;
                    this.places.push(res.data.newPlace);
                    this.clearForm();
                    this.$message({
                        message: res.data.message,
                        type: "success",
                    });
                })
                .catch((error) => {
                    this.waiting = false;
                    console.error(error);
                    this.$message.error(
                        "Ha ocurrido un error al crear el lugar o zona"
                    );
                });
        },
        submitEdit(placeData) {
            this.waiting = true;
            const url = `${window.location.origin}/places/update/${this.editedPlaceId}`;
            axios
                .put(url, placeData)
                .then((res) => {
                    this.waiting = false;
                    const index = this.index;
                    this.places[index] = res.data.place;
                    this.clearForm();
                    this.clearEditData();
                    this.$message({
                        message: res.data.message,
                        type: "success",
                    });
                })
                .catch((error) => {
                    this.waiting = false;
                    console.error(error);
                });
        },
    },
};
</script>
